<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                德邦证券携手非凸科技走进“乘长会客厅”
              </p>
              <div class="fst-italic mb-2">2023年9月7日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >德邦证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    8月31日，非凸科技受邀参加德邦证券在上海举办的“乘长会客厅”活动，与机构投资者、私募管理人等特邀嘉宾，共同探讨前沿算法，分享独特见解，促进生态伙伴共同成长。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/02-宋丽娟.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    德邦证券致力于成为“全球领先的科技投行和财富管理平台”，聚焦数字金融领域，通过“敏前台+强中台+稳后台”的科技基石赋能大财富、大资管、新经济业务数字化。德邦证券打造的“私募乘长计划”，旨在持续挖掘与赋能潜力私募管理人，通过长效私募孵化机制，助力私募管理人成长，满足个人及机构客户财富长期保值增值的配置需求，构建共赢生态圈。会上，德邦证券交易服务中心行政负责人宋丽娟表示，德邦证券将充分发挥内外部资源优势，与机构投资者展开全方位、多层次、宽领域的合作共赢，发挥协同效应，共谋长远发展。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/03-周升.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技机构业务经理周升以“算法服务，为投资交易锦上添花”为主题进行了分享。非凸科技提供标准化及个性化的算法产品，全方位满足机构投资者及个人高净值投资者降低交易成本、优化交易执行、提升交易效率等需求。除此之外，也在深入观察行业发展态势，倾听客户诉求，构建功能齐全、高性能、高可用的非凸智能交易总线系统。该系统以市场领先的主动算法、底仓增强算法为核心，搭配各类交易工具、管理工具，以及包括绩效分析系统、数据回测系统、模拟仿真系统、运维监控系统、智能路由系统等在内的其他功能，满足所有交易需求，致力于为客户提供稳定、优质、全面的一站式金融交易服务。周升表示，影响算法绩效的纬度有多方面，比如母单时长、交易时间段、母单市占比、标的价格等任务因素，以及系统性能、硬件性能、上下游延迟等系统因素，希望投资者能够更深入地看待和分析算法绩效。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/04-霁泽投资.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    霁泽投资是一家致力于专业做低风险投资的私募基金管理人，核心理念是聚焦自己擅长的领域，不断精进，希望在较小风险的情况下为投资者赚取较为理想的长期复利。霁泽投资市场总监徐浙东做了主题演讲“稳中求进，震荡前行下的套利赋能”，霁泽投资擅长在纷繁复杂的市场环境中寻找不对称信息，以敏锐的眼光发现市场中的套利机会。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/05-兆信基金.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    江苏兆信是一家以研究为基础，灵活运用衍生品工具，不断优化资产组合风险收益比的多资产多策略宏观对冲基金。江苏兆信市场总监王瑛分享了“期权卖方策略的ABC”，期权卖方作为主要的期权策略以其胜率高、容量大为广大投资者喜爱，但其中蕴含的风险也不可忽视，当面临巨大损失风险时，要及时止损离场、移仓或对冲。兆信基金在资金层、策略层、交易层及产品层均设置了风险控制，以其独特的风控止损方式使得策略风险收益较高，受市场波动影响较小。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/56/06-夏文琦.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，德邦证券上海乍浦路证券营业部总经理夏文琦作为此次活动的主持人，带领大家展开了一次深度、高效的交流与互动，有对市场的观察，有对算法的理解，也有对未来合作的展望。
                  </p>
                  <p>
                    目前，非凸科技与德邦证券已达成深度战略合作，算法总线系统上线在即。未来，非凸科技将持续加强投研能力和投研人才的培养与建设，与德邦证券协力为客户提供智能算法能力与高性能交易体验，为行业前行贡献一份力量。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News56",
};
</script>

<style></style>
